import * as React from "react";
import PageWrapper from "../components/PageWrapper";
import ContactForm from "../components/ContactForm";
import * as styles from "../styles/modules/Contact.module.css"

const contact = () => {
  return (
    <PageWrapper>
      <section className={styles.contactSection}>
        <aside className={styles.asideContact} >
          <h2 style={{fontWeight: "normal"}}>contact@mariannealliot.com</h2>
          <hr style={{ width: "2ch", marginLeft: 0, border: "none", backgroundColor: "black", height: "3px" }} />
          <h3 style={{fontWeight: "semibold"}}>Infos galeries</h3>
          <h4 style={{fontWeight: "semibold"}}><a href="http://laminigalerie.com/" className={styles.simpleLink}>La MINI Galerie</a></h4>
          <p style={{color: "#707070"}}>23 Rue Fleuriau, 17000 La Rochelle</p>
          <p style={{color: "#707070"}}>contact@laminigalerie.com</p>
          <h4 style={{fontWeight: "semibold"}}>Galerie Albane</h4>
          <p style={{color: "#707070"}}>1 Rue Suffren, 44000 Nantes</p>
          <p style={{color: "#707070"}}>info@galerie-albane.com</p>
        </aside>
        <aside className={styles.mobileAsideContact} >
          <div style={{width: "100%"}}>
            <h3 style={{fontWeight: "normal"}}>Infos galeries :</h3>
            <p style={{fontWeight: "normal"}}>contact@mariannealliot.com</p>
          </div>
          <div style={{width: "50%"}}>
            <h2 style={{fontWeight: "semibold"}}><a href="http://laminigalerie.com" className={styles.simpleLink}>La MINI Galerie</a></h2>
            <p style={{color: "#707070"}}>23 Rue Fleuriau, 17000 La Rochelle</p>
            <p style={{color: "#707070"}}>contact@laminigalerie.com</p>
          </div>
          <div style={{width: "50%", textAlign: "right"}}>
            <h2 style={{fontWeight: "semibold"}}>Galerie Albane</h2>
            <p style={{color: "#707070"}}>1 Rue Suffren, 44000 Nantes</p>
            <p style={{color: "#707070"}}>info@galerie-albane.com</p>
          </div>
        </aside>
        <ContactForm></ContactForm>
      </section>
    </PageWrapper>
  );
};

export default contact;
