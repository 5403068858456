import * as React from "react"
import styled from "styled-components"
import {useState} from "react"
import {useRef} from "react"
import {motion} from "framer-motion"

const StyledInput = styled(motion.fieldset)`
  border: none;
  display: flex;
  flex-direction: column;
  margin: .5rem 0;
  width: 100%;
  padding: 0;

  label {
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: ${props => props.showLabel ? 1 : 0};
  }

  input,
  textarea {
    border: none;
    border-bottom: 1px solid black;
    line-height: 18px;
    resize: none;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
`



const InputWrapper = ({ name, label, required, type, textarea, variants }) => {
    const textAreaEl = useRef(null);
    const [value,setValue] = useState("")

    function auto_grow() {
         textAreaEl.current.style.height = "30px";
         textAreaEl.current.style.height = (textAreaEl.current.scrollHeight)+"px";
    }

  return (
    <StyledInput variants={variants} showLabel={value === ""}>
      <label htmlFor={name}>{label}</label>
      {textarea ? (
        <textarea onReset={() => setValue("")} style={{height: 30}} ref={textAreaEl} onInput={e => {setValue(e.target.value); auto_grow()}} id={name} name={name} onChange={e => setValue(e.target.value)}></textarea>
      ) : (
        <input onReset={() => setValue("")} name={name} type={type} id={name} onChange={e => setValue(e.target.value)} required={required}></input>
      )}
    </StyledInput>
  )
}

export default InputWrapper
